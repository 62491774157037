import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  h3 {
    margin-top: 2em;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;}
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;

    a {
        color: currentColor;
    }
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 1rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Na víkend do Dublinu</Title>
            <Info>
                <Author>Julia</Author>
                <Date>1. 3. 2024</Date>
            </Info>

            <p>Pokud máte před sebou jen tři dny a rozhodli jste se strávit je v Dublinu, připravte se na nezapomenutelnou cestu plnou piva, whiskey, tradičních pokrmů a živé hudby. Irská pohostinnost a kouzlo Dublinu vás jistě vtáhnou do svého světa.</p>
            <h3>Den 1: Kulturní Procházka a Místní Chuťovky</h3>
            <p>Náš irský výlet začal kolem půl druhé odpoledne, kdy jsme přistáli v Dublinu. Do centra města se můžete dostat buď autobusem (lístek na letišti stojí 10 EUR/osobu) nebo taxíkem je to cca 15-25 min a za 20-25 EUR. Ubytovali jsme se téměř v centru, konkrétně jsme zvolili Holiday Inn Express Dublin City Center (IHG Hotels).</p>
            <p>Na hotelu jsme si dopřáli chvilkový voraz a vyrazili do centra. Na prvního vychlazeného Guinesse a místní dobroty jsme nemuseli daleko a zastavili jsme se hned v blízkosti hotelu v <a href="https://maps.app.goo.gl/wkzYewdHzuujV5ma8">Madigans O'Connell Street</a>. Následně okouzleni místní architekturou a atmosférou jsme si opět dopřáli dalšího Guinesse v <a href="https://maps.app.goo.gl/rB8zMHoBn5U916X57">The Old Stand</a>.</p>

            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_1.jpg" alt="Ulice v Dublinu"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_2.jpg" alt="Ulice v Dublinu"></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Zbytek večera jsme si užívali příjemného, skoro až jarního počasí, procházku městem, návštěvu ještě několika podniků a ochutnávkou whisky v <a href="https://maps.app.goo.gl/YwuqZQDxNxihvfXu8">The Whiskey Reserve</a>.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/dublin/dublin_3.jpg" alt="The Whiskey Reserve"></StaticImage>
            </StyledFigure>

            <h3>Den 2: Příběh Guinnessu a Historie Piva</h3>
            <p>Svou cestu můžete začít snídaní v tradiční irské kavárně, kde si vychutnáte klasickou irskou snídani s vejci, slaninou a černým pudinkem. My jsme si sice dopřáli snídani na hotelu, ale na kávu jsme vyrazili do města. Objevili jsme naprosto famózní skromnou kavárnu a na jejich brownie myslím ještě doteď (na hledání kaváren s dobrou kávou doporučuji aplikaci <strong>European Coffee Trip</strong> - <a href="https://play.google.com/store/apps/details?id=cz.europeancoffeetrip.android&pcampaignid=web_share">Google Play</a>, <a href="https://apps.apple.com/us/app/european-coffee-trip/id1524488773">App Store</a>).</p>
            <p>Následně jsme si udělali procházku kolem <strong>Christ Church Cathedral</strong> a katedrály <strong>Sv. Patrika</strong>, kde zrovna v parku probíhaly lokální trhy se šperky, knihami, oblečením a různými doplňky (já si odnesla prstýnek 😃). Pak jsme pokračovali do krásného parku St. Stephen Green Park - naprosta oáza klidu. Jaro zde bylo již v plném proudu. Rozkvetlé kytky, zelené travnaté plochy, kvetoucí stromy a všude kolem přítomný zpěv ptáků.</p>
            <p>Hned za rohem od parku je turisticky známá ulička The Umbrellas Dublin, která shodou okolností byla úplně prázdná - ideální čas na fotku! 🙂 K obědu jsme si ještě dopřáli Fish & Chips, abychom se posilnili na důležitou návštěvu.</p>
            <p>Odpoledne jsme se vydali k ikonickému Guinness Storehouse, srdci irského piva. Můžete zde si projít historii a výrobou piva a završit svou návštěvu v baru s úžasným výhledem na město, kde v rámci vstupného máte pintu Guinessu zdarma (<span class="italic">cena vstupenky cca 30 EUR, doporučují zakoupit pár dní předem, abyste si vybrali vyhovující čas</span>). My osobně jsme měli asi trošku jiná očekávání. Pokud jste někdy byli na prohlídce Prazdroje v Plzni nebo Staropramenu v Praze, tak toto je něco úplně jiného.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_4.jpg" alt="Guinness Storehouse"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_5.jpg" alt="Guinness Storehouse"></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>U vstupu si vyzvednete audio guide a celý storehouse procházíte sami, podle šipek a ukazatelů, které jsou občas trochu nečitelné. Nejvíce ze všeho se nám líbilo vystoupení v jedné části prohlídky, kde hrála kapela, tančil se irský step a následovala hra na harfu.</p>
            <p>Celý tento den jsme opět úspěšně zakončili v tradičním irském baru, kde jsme si vychutnávali Guiness a živou muziku, která je zde v každém baru naprosto běžnou záležitosti.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_6.jpg" alt="Bar v Dublinu"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_7.jpg" alt="Bar v Dublinu"></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <h3>Den 3: Jameson Destillery a nákup suvenýrů</h3>
            <p>Ráno jsme začali opět návštěvou včera objevené kavárny. Pak jsme měli naplánovanou návštěvu <strong>Jameson Destillery</strong>, kde jsme měli komentovanou prohlídku včetně ochutnávky whisky. Jameson není zrovna naše nejoblíbenější whisky (vlastně whisky nikdy nepatřil mezi mé oblíbené nápoje 😃, víc tomu rozumí přítel), ale tato prohlídka byla velmi příjemná a zakončili jsme ji v místním baru u koktejlu Jameson s Ginger beer, který nám chutnal natolik, že jsme si jich dali asi 2 nebo 3 😃 (po ochutnávce v 11:30 dopoledne a prvním koktejlu jsme přestali počítat).</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_8.jpg" alt="Ulice v Dublinu"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_9.jpg" alt="Ulice v Dublinu"></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Po několika koktejlech jsme se vydali nakoupit pár suvenýrů vedle do obchůdku.</p>
            <p>Pak jsme opět pokračovali procházkou podél řeky a obdivováním nádherných budov. Ten kontrast původní architektury a moderních staveb nás vlastně velmi bavil.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/dublin/dublin_10.jpg" alt=""></StaticImage>
            </StyledFigure>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/dublin/dublin_11.jpg" alt=""></StaticImage>
            </StyledFigure>
            <p>Celý den jsme opět zakončili návštěvou tradičního pubu a ochutnáváním <strong>Irish stew</strong>. Pak jsme si ještě poslechli pár písniček v jiném baru a vyrazili si odpočinout na hotel a nabrat síly na brzký ranní let do Prahy. </p>
            <p>Už teď víme, že návštěva Irska rozhodně nebyla poslední 🙂</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_12.jpg" alt="Ulice v Dublinu"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/dublin/dublin_13.jpg" alt="Ulice v Dublinu"></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Pokud máte více času, rozhodně ještě stojí za to navštívit <strong>Phoenix Park</strong>, největší obytný park v Evropě. Můžete zde potkat i stádo divokých jelenů, <strong>Kilmainham Gaol</strong>, bývalý vězeňský komplex s bohatou historií a prohlédnout si místní muzea.</p>
            <p>Po třech dnech plných historie, chutí a hudby opouštíme Dublin s plným srdcem a vzpomínkami na nezapomenutelné irské dobrodružství. Sláinte!</p>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
